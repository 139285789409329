/**
 * @file stepper.js
 * @module Stepper
 * @description Manages the visual state of a multi-step form or wizard.
 *
 * This module handles updating the visual appearance of the stepper based on step changes
 * triggered by external events (assumed to be defined elsewhere). It updates the active
 * state of step headers, hides/shows step content and navigation buttons,
 * and manages visibility and disabled state based on current step.
 *
 * @requires jQuery
 * @requires ./subscriptionEnum - for event definitions (assumed)
 *
 * @exports {function} init - Initializes the stepper visual state management.
 */

import $ from 'jquery';
import { Events } from './subscriptionEnum';

const Stepper = () => {
	/**
	 * @typedef {object} StepperElements
	 * @property {string} container - CSS selector for the stepper container element.
	 * @property {string} step - CSS selector for individual step elements.
	 * @property {string} button - CSS selector for navigation buttons (all share this base).
	 * @property {string} header - CSS selector for the stepper header element.
	 * @property {string} headerItem - CSS selector for step headers within the header.
	 */
	/**
	 * Configuration options for the stepper visual state management.
	 * @type {StepperElements}
	 */

	const el = {
		container: '.mi-offerte-stepper',
		step: '.mi-offerte-stepper__step',
		button: '.mi-offerte-stepper__button',
		header: '.mi-offerte-stepper__header',
		headerItem: '.mi-offerte-stepper__stepHead--item',
		reinegen : 'input[name="reinegen"]',
	};

	let $container = $();
	let alreadyAddToCart = false;

	/**
	 * Defines a cached reference to the stepper container element.
	 */
	const defineVar = () => {
		$container = $( el.container );
	};

	/**
	 * Initializes the stepper visual state management.
	 * This function ensures the DOM is ready before defining variables and checks
	 * if the container element exists to proceed with initialization.
	 */
	const init = () => {
		$( function () {
			defineVar();
			if ( $container.length ) {
				exec();
			}
		} );
	};

	/**
	 * Attaches event listeners and performs initial setup.
	 * This function listens for step increment/decrement events, updates button data attributes,
	 * and toggles class names on header items and step content based on the current step. It also
	 * handles initial state on the first and last steps.
	 */
	const exec = () => {
		$( document ).on( Events.STEP_INCREMENT, function ( event ) {
			// Extract step information from the event
			let { currSteps: prevStep, nextSteps: currStep } = event.detail;
			let nextStep = currStep + 1;
			// Define header item
			let $headerItem = $container.find( el.header ).find( el.headerItem );
			let $nextBtn = $container.find( `${ el.button }.next` );

			if ( currStep > $headerItem.length && $nextBtn.hasClass( 'js-checkout' ) ) {
				if ( ! alreadyAddToCart ) {
					$( document ).trigger( Events.ADD_TO_CART );
					alreadyAddToCart = true;
				} else {
					alert( 'already last step' );
				}
				return;
			}

			// Update data-step attribute for next and previous buttons
			$container.find( `${ el.button }.next` ).attr( 'data-step', nextStep );
			$container.find( `${ el.button }.prev` ).attr( 'data-step', prevStep );

			// Find the previous header item
			let $prevHeaderItem = $headerItem.eq( prevStep - 1 );

			// If the previous header item is active, mark it as done
			if ( $prevHeaderItem.is( '.active' ) ) {
				$prevHeaderItem.toggleClass( 'active done' );
			}

			// Mark the current step header as active
			$container
				.find( el.header )
				.find( el.headerItem )
				.eq( prevStep )
				.toggleClass( 'active' );

			// Find the previous and current step elements
			let $prevStep = $container.find( `${ el.step }${ prevStep }` );
			let $currStep = $container.find( `${ el.step }${ currStep }` );

			// Handle the first step
			if ( prevStep === 0 ) {
				// Hide the previous step, column 2, and header
				$prevStep.add( `${ el.step }_col-2, ${ el.header }` ).toggleClass( 'hide' );
				// Show and enable all buttons
				$container.find( el.button ).each( function () {
					let $this = $( this );
					if ( $this.is( '.hide' ) ) {
						$this.toggleClass( 'hide' ).removeAttr( 'disabled' );
					}
				} );
			} else {
				// Hide the previous and current steps
				$prevStep.add( $currStep ).toggleClass( 'hide' );
			}

			// Prevent to next step after last defined step
			if ( currStep === $headerItem.length ) {
				$nextBtn.addClass( 'js-checkout' );
			}
		} );

		$( document ).on( Events.STEP_DECREMENT, function ( event ) {
			// Extract step information from the event
			let { currSteps: nextStep, prevSteps: currStep, reset } = event.detail;
			let prevStep = currStep - 1;

			$container.find( `${ el.button }.next` ).removeClass( 'js-checkout' );

			// Update data-step attribute for next and previous buttons
			$container.find( `${ el.button }.next` ).attr( 'data-step', nextStep );
			$container.find( `${ el.button }.prev` ).attr( 'data-step', prevStep );

			// Find the previous header item
			let $prevHeaderItem = $container
				.find( el.header )
				.find( el.headerItem )
				.eq( currStep - 1 );

			// If the previous header item is done, mark it as active
			if ( $prevHeaderItem.is( '.done' ) ) {
				$prevHeaderItem.toggleClass( 'active done' );
			}

			// Mark the current step header as active
			$container
				.find( el.header )
				.find( el.headerItem )
				.eq( currStep )
				.toggleClass( 'active' );

			// Find the current and next step elements
			let $currStep = $container.find( `${ el.step }${ currStep }` );
			let $nextStep = $container.find( `${ el.step }${ nextStep }` );

			// Handle the first step
			if ( prevStep === -1 ) {
				// Hide the current step, column 2, and header
				$currStep.add( `${ el.step }_col-2, ${ el.header }` ).toggleClass( 'hide' );
				// Hide and disable all buttons except next
				$container
					.find( el.button )
					.not( '.next' )
					.filter( ':visible' )
					.toggleClass( 'hide' )
					.attr( 'disabled', true );

				if ( reset ) {
					$container
						.find( el.button + '.next' )
						.attr( 'disabled', 'true' )
						.prop( 'disabled', true );
				}
			} else {
				// Hide the current and next steps
				$currStep.add( $nextStep ).toggleClass( 'hide' );
			}
		} );
	};

	init();
};

try {
	Stepper();
} catch ( error ) {
	throw Error( error );
}
