import $ from 'jquery';
import { debounce } from '../../utils/utils';

const offerteStepper = () => {
	const el = {
		container: '.mi-offerte-stepper',
		target: {
			header: '.mi-offerte-stepper__header',
			footer: '.mi-offerte-stepper__footer',
		},
	};

	let isDisconnected = false;

	const init = () => {
		$( function () {
			if ( $( el.container ).length ) {
				exec();
			}
		} );
	};

	const exec = () => {
		const headerEl = document.querySelector( el.target.header );
		const footerEl = document.querySelector( el.target.footer );

		const headerObserver = new IntersectionObserver(
			( [ e ] ) => {
				e.target.classList.toggle( 'is-pinned', e.intersectionRatio < 1 );
			},
			{ threshold: [ 1 ] }
		);

		const footerObserver = new IntersectionObserver(
			( [ e ] ) => {
				e.target.classList.toggle( 'is-pinned', e.intersectionRatio < 1 );
			},
			{ threshold: [ 1 ] }
		);

		$( window ).on(
			'load resize',
			debounce( function () {
				if ( $( this ).outerWidth() < 601 ) {
					headerObserver.observe( headerEl );
					footerObserver.observe( footerEl );
					isDisconnected = false;
				} else if ( $( this ).outerWidth() > 600 && ! isDisconnected ) {
					headerObserver.disconnect();
					footerObserver.disconnect();
					isDisconnected = true;
				}
			}, 150 )
		);
	};

	init();
};

try {
	offerteStepper();
} catch ( error ) {
	throw Error( error );
}
