import $ from 'jquery';

const hoverTitleTooltip = () => {
	const el = {
		container: '.information-badge',
		target: '.tooltip-info',
	};

	const init = () => {
		document.addEventListener( 'DOMContentLoaded', function () {
			if ( document.querySelector( el.target ) !== null ) exec();
		} );
	};

	const exec = () => {
		const $container = $( el.container );

		$container
			.on( 'mouseover', function () {
				$( this ).find( el.target ).fadeIn( 400 );
			} )
			.on( 'mouseleave', function () {
				$( this ).find( el.target ).fadeOut( 400 );
			} );
	};

	init();
};

try {
	hoverTitleTooltip();
} catch ( error ) {
	throw Error( error );
}
