/**
 * @module inputRange
 * @description Handles interactions with a quantity range input element and updates
 * associated label, hidden field, and total detail element within a stepper.
 *
 * This module defines element selectors, caches jQuery objects, and sets up event
 * listeners for the quantity range input. It also handles formatting the displayed
 * value, updating related elements, and dispatching a custom event upon user interaction.
 */
import $ from 'jquery';
import { Events } from './subscriptionEnum';
import { debounce } from '../../utils/utils';

const inputRange = () => {
	const el = {
		container: '.mi-offerte-stepper',
		parentWrapper: '.mi-offerte-stepper__subscriptionQuantity',
		wrapper: '.mi-offerte-stepper__rangeWrapper',
		target: 'input[type="range"]#inputRange_quantity',
		label: 'label[for="inputRange_quantity"]',
		quantity: 'input[type="number"][name="subscription_quantity"]',
		quantityVisual: '#inputRange_quantity_visual',
		panelCount: '.js-panel-quantity-input',
		totalPrice: '.js-price-panel-total',
		nextBtn: '.next.js-navigation',
		stepperDetail: {
			total: '.mi-offerte-stepper__details #inputVisual_total',
		},
	};

	let $target = $();
	let $inputFieldQuantity = $();
	let $stepperTotalDetail = $();
	let $inputRangeLabel = $();
	let currValueString;
	let maxValue = 100;
	let isInteracted = false;

	/**
	 * Defines and caches jQuery objects for frequently used elements.
	 */
	const defineVar = () => {
		$target = $( el.container ).find( el.target );
		$inputFieldQuantity = $( el.container ).find( el.quantity );
		$stepperTotalDetail = $( el.container ).find( el.stepperDetail.total );
		$inputRangeLabel = $( el.parentWrapper ).find( el.label );
		maxValue = parseInt( $target.attr( 'data-max-actual' ) );
	};

	/**
	 * Initializes the module.
	 */
	const init = () => {
		$( function () {
			defineVar();
			if ( $target.length ) {
				$target.val( $target.attr( 'min' ) );
				exec();
				const $label = $target.closest( el.wrapper ).find( el.label );
				$label.text( $target.val() );
			}
		} );
	};

	/**
	 * Attaches event listeners to the quantity range input element.
	 */
	const exec = () => {
		$( document ).on( 'input', el.target, handleChangeInputRange );

		$( document ).on( 'change', el.target, debounce( handleRangeChange, 150 ) );

		$( document ).on( Events.STEP_DECREMENT, function ( event ) {
			const { prevSteps } = event.detail;
			if ( prevSteps === 0 ) {
				isInteracted = false;
				$( '.next.js-navigation' ).attr( 'disabled', 'true' ).prop( 'disabled', true );
			}
		} );

		$( document ).on( 'input', el.quantity, debounce( handleChangeInputQty, 150 ) );
	};

	const handleRangeChange = ( event ) => {
		const reset = event?.detail?.reset;
		// Dispatch custom event only on the first interaction
		if ( ! isInteracted && reset !== true ) {
			handleInteractedEvent( event );
			isInteracted = true;
		}
	};

	/**
	 * Handles user interaction with the quantity range input.
	 *
	 * @param {Event} event - The input event object.
	 */
	const handleChangeInputRange = ( event ) => {
		const elCurrTarget = event.currentTarget;
		const currValue = parseFloat( elCurrTarget.value );
		currValueString = currValue.toString();

		$( elCurrTarget )
			.closest( el.wrapper )
			.find( '#inputRange_quantity_visual' )
			.val( currValue );

		try {
			// Format the displayed value based on range
			defineValueString( currValue );

			$inputFieldQuantity.off( 'change' );
			$inputFieldQuantity.val( currValueString === `${ maxValue }+` ? maxValue : currValue );
			$inputFieldQuantity.on( 'change', handleChangeInputQty );

			changeLabelQty( currValue );
			// bypassInputEvent = false;
		} catch ( err ) {
			console.error( err.message );
		}
	};

	const calculateTextwidth = ( $el, value, isCenter = false ) => {
		const textWidth = getTextWidth(
			value,
			`${ $el.css( 'font-size' ) } ${ $el.css( 'font-family' ).split( ',' )?.at( 0 ) }`
		);

		let leftPos = textWidth + 12;
		if ( isCenter ) {
			leftPos = ( $el.outerWidth() + leftPos + 12 ) / 2;
		}

		let $suffix = $el.closest( '.row' ).find( '.suffix' );
		$suffix.show();
		$suffix.css( { left: leftPos } );
	};

	const removeInputSuffix = ( $el = $() ) => {
		let $suffix = $el.closest( '.row' ).find( '.suffix' );
		if ( $suffix.is( ':visible' ) ) {
			$suffix.hide();
		}
	};

	const getTextWidth = ( text, font ) => {
		// re-use canvas object for better performance
		var canvas =
			getTextWidth.canvas || ( getTextWidth.canvas = document.createElement( 'canvas' ) );
		var context = canvas.getContext( '2d' );
		context.font = font;
		var metrics = context.measureText( text );
		return Math.ceil( metrics.width );
	};

	const handleChangeInputQty = ( event ) => {
		const $this = $( event.currentTarget );
		let parsedValue;

		if ( $this.val() > parseInt( $target.attr( 'data-max-actual' ) ) ) {
			parsedValue = $target.attr( 'data-max-actual' );
			defineValueString( $this.val() );
		} else if ( $this.val() < parseInt( $target.attr( 'min' ) ) ) {
			parsedValue = $target.attr( 'min' );
			$this.val( $target.attr( 'min' ) );
			defineValueString( $target.attr( 'min' ) );
		} else {
			parsedValue = $this.val();
			defineValueString( $this.val() );
		}

		$target.off( 'change' );
		$target.val( $this.val() );
		$target.on( 'change', handleChangeInputRange );

		$( el.quantityVisual ).off( 'change' );
		$( el.quantityVisual ).val( $this.val() );
		$( el.quantityVisual ).on( 'change', handleChangeInputRange );

		changeLabelQty( $this.val() );
		$this.val( parsedValue );
	};

	const changeLabelQty = ( currValue ) => {
		$inputRangeLabel.text( currValueString );
		$stepperTotalDetail.val( currValue > maxValue ? maxValue : currValue );

		if ( currValue > maxValue ) {
			calculateTextwidth( $stepperTotalDetail, maxValue, false );
			calculateTextwidth( $inputFieldQuantity, maxValue, true );
		} else {
			removeInputSuffix( $stepperTotalDetail );
			removeInputSuffix( $inputFieldQuantity );
		}
	};

	const defineValueString = ( currValue ) => {
		if ( currValue > maxValue ) {
			currValueString = `${ maxValue }+`;
		} else if ( currValue >= 10 ) {
			currValueString = currValue;
		} else if ( currValue > 0 ) {
			currValueString = `0${ currValue }`;
		} else {
			currValueString = currValue;
		}
	};

	/**
	 * Creates and dispatches a custom event indicating user interaction with the quantity range input.
	 *
	 * @param {Event} event - The input event object.
	 */
	const handleInteractedEvent = ( event ) => {
		const currentStep = Array.from(
			$( event.target ).closest( el.container + '__step' )[ 0 ].classList
		)
			.map( ( className ) => parseInt( className.replace( 'mi-offerte-stepper__step', '' ) ) )
			.find( ( number ) => ! isNaN( number ) );

		const interactedEvent = new CustomEvent( Events.FORM_IS_INTERACTED, {
			detail: {
				currSteps: currentStep,
				value: event.target.value,
			},
		} );

		document.dispatchEvent( interactedEvent );
	};

	init();
};

try {
	inputRange();
} catch ( error ) {
	throw Error( error );
}
