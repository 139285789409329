import $ from 'jquery';
import { Events } from '../subscription-quantity/subscriptionEnum';
import { dispatchInteractedEvent } from '../subscription-quantity/dispatchEvent';

const el = {
	subscriptionType: '.js-subscription-type',
	quantityInput: '.js-panel-quantity-input',
	quantityInputRange: '.js-panel-quantity-range',
	pricePanel: '.js-price-per-panel',
	pricePanelTotal: '.js-price-panel-total',

	reinegen: 'input[name="reinegen"]',
	monitors: 'input[name="monitor[]"]',
	maintenances: 'input[name="maintenance[]"]',

	additionalCost: {
		monitor: '.js-additional-cost-monitor',
		maintenance: '.js-additional-cost-maintenance',
	},

	navigationButton: {
		next: '.js-navigation.next',
	},

	subscriptionOverview: '.js-subscription-overview',

	checkout: '.js-checkout',

	loaderTarget: {
		container: '.mi-offerte-stepper',
	},
};

let labelNextButton;

const addEventListener = () => {
	$( document ).on( 'change', el.subscriptionType, function () {
		calculatePrice( $( el.loaderTarget.container ), 'spinner' );
	} );

	$( document ).on( 'change', el.quantityInput, function () {
		calculatePrice( $( el.loaderTarget.container ), 'spinner' );
	} );

	$( document ).on( 'change', el.quantityInputRange, function () {
		calculatePrice( $( el.loaderTarget.container ), 'spinner' );
	} );

	$( document ).on( 'change', el.reinegen, function () {
		calculatePrice( $( el.subscriptionOverview ), 'spinner' );
	} );

	$( document ).on( 'change', el.monitors, function () {
		calculatePrice( $( el.subscriptionOverview ), 'spinner' );
	} );

	$( document ).on( 'change', el.maintenances, function () {
		calculatePrice( $( el.subscriptionOverview ), 'spinner' );
	} );

	$( document ).on( 'change', el.additionalCost.monitor, function () {
		// calculatePrice();
		calculatePrice( $( el.subscriptionOverview ), 'spinner' );
	} );

	$( document ).on( 'change', el.additionalCost.maintenance, function () {
		// calculatePrice();
		calculatePrice( $( el.subscriptionOverview ), 'spinner' );
	} );

	$( document ).on( Events.ADD_TO_CART, function ( e ) {
		e.stopImmediatePropagation();
		addToCart( $( el.loaderTarget.container ), 'spinner' );
	} );

	$(el.quantityInputRange).trigger('change')
	dispatchInteractedEvent( 1, 5 );
};

const addToCart = ( $loader_el = $(), animation ) => {
	const subscriptionDetail = getSubscriptionData();

	// eslint-disable-next-line no-undef
	subscriptionDetail.action = subscription_add_to_cart.action;

	// eslint-disable-next-line no-undef
	subscriptionDetail.nonce = subscription_add_to_cart.nonce;

	let classAnimation;

	if ( $loader_el.length ) {
		$loader_el.css( {
			transition: 'opacity 0.3s ease-in-out',
		} );

		switch ( animation ) {
			case 'blinking':
				classAnimation = 'loading-blink';
				break;
			case 'spinner':
				classAnimation = 'loading-spinner';
				break;
			default:
				classAnimation = 'loading-blink';
				break;
		}

		if ( ! $loader_el.hasClass( classAnimation ) ) {
			$loader_el.toggleClass( classAnimation );
		}
	}

	$.ajax( {
		method: 'POST',
		// eslint-disable-next-line no-undef
		url: subscription_add_to_cart.url_admin_ajax,
		data: subscriptionDetail,
	} )
		.done( ( ) => {
			// console.log( response );

			// if ( $loader_el.length && $loader_el.hasClass( classAnimation ) ) {
			// 	$loader_el.toggleClass( classAnimation );
			// }

			// eslint-disable-next-line no-undef
			window.location.assign( parameters.checkout_url );
		} )
		.fail( ( error ) => {
			console.log( error );
		} );
};

const getSubscriptionData = () => {
	const subscriptionType = getSubscriptionType();
	const panelTotal = getPanelTotal();

	return {
		subscription_type: subscriptionType,
		panel_count: panelTotal,
		reignen: getReinegen() ?? null,
		monitors: getMonitors() ?? [],
		maintenances: getMaintenances() ?? [],
		additional_cost: {
			monitor: getAdditionalCostMonitor() ?? false,
			maintenance: getAdditionalCostMaintenance() ?? false,
		},
	};
};

const calculatePrice = ( $loader_el = $(), animation ) => {
	let calculatePriceData = getSubscriptionData();

	// eslint-disable-next-line no-undef
	calculatePriceData.action = calculate_subscription.action;

	// eslint-disable-next-line no-undef
	calculatePriceData.nonce = calculate_subscription.nonce;

	let classAnimation;

	if ( $loader_el.length ) {
		$loader_el.css( {
			transition: 'opacity 0.3s ease-in-out',
		} );

		switch ( animation ) {
			case 'blinking':
				classAnimation = 'loading-blink';
				break;
			case 'spinner':
				classAnimation = 'loading-spinner';
				break;
			default:
				classAnimation = 'loading-blink';
				break;
		}

		if ( ! $loader_el.hasClass( classAnimation ) ) {
			$loader_el.toggleClass( classAnimation );
		}
	}

	$.ajax( {
		// eslint-disable-next-line no-undef
		url: calculate_subscription.url_admin_ajax,
		method: 'POST',
		data: calculatePriceData,
	} )
		.done( function ( response ) {
			$( el.pricePanel ).html( response.data.price_per_panel );

			let subscriptionType = calculatePriceData.subscription_type;
			if ( subscriptionType === 'annual' ) {
				$( '.product-price.annual, .price.annual' ).show();
				$( '.product-price.monthly, .price.monthly' ).hide();
			} else {
				$( '.product-price.annual, .price.annual' ).hide();
				$( '.product-price.monthly, .price.monthly' ).show();
			}

			const elPanelCount 		= '#inputRange_quantity';
			const panelCountValue 	= $( elPanelCount ).val();

			// if ( ! response.data.price_per_panel_raw ) {
			if ( panelCountValue > 100 ) {
				$( el.pricePanelTotal ).html(
					'<span class="heading-h-6-semibold primary-900">' +
						$( el.pricePanelTotal ).attr( 'data-label-quotation' ) +
						'</span>'
				);
				if ( ! labelNextButton ) {
					labelNextButton = $( el.navigationButton.next ).text();
				}
				$( el.navigationButton.next ).text( 'Contact' );
			} else {
				$( el.pricePanelTotal ).html( response.data.total );
				// console.log( labelNextButton );
				if ( labelNextButton ) {
					$( el.navigationButton.next ).text( labelNextButton );
				}
			}

			$( el.subscriptionOverview ).html( response.data.subscription_oveview );

			if ( $loader_el.length && $loader_el.hasClass( classAnimation ) ) {
				$loader_el.toggleClass( classAnimation );
			}

			console.log( response );
		} )
		.fail( ( error ) => {
			console.log( error );
		} );
};

const getSubscriptionType = () => {
	return $( `${ el.subscriptionType }:checked` ).val();
};

const getPanelTotal = () => {
	return $( el.quantityInput ).val();
};

const getAdditionalCostMonitor = () => {
	return $( `${ el.additionalCost.monitor }:checked` ).val() === 'true';
};

const getAdditionalCostMaintenance = () => {
	return $( `${ el.additionalCost.maintenance }:checked` ).val() === 'true';
};

const getReinegen = () => {
	return $( `${ el.reinegen }:checked` ).val();
};

const getMonitors = () => {
	const $monitors = $( `${ el.monitors }:checked` );
	let monitors = [];

	$monitors.each( ( i, el ) => {
		monitors.push( $( el ).val() );
	} );

	return monitors;
};

const getMaintenances = () => {
	const $maintenances = $( `${ el.maintenances }:checked` );
	let maintenances = [];

	$maintenances.each( ( i, el ) => {
		maintenances.push( $( el ).val() );
	} );

	return maintenances;
};

const init = () => {
	addEventListener();
};

try {
	init();
} catch ( error ) {
	console.log( error );
}
