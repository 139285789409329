/**
 * @file buttonStepper.js
 * @module buttonStepper
 * @description Manages button behavior for a multi-step form or wizard.
 *
 * This module dynamically enables/disables navigation buttons (next, previous, cancel)
 * based on the current step and whether the step is marked as required. It also handles
 * click events for the next and previous buttons to trigger step changes.
 *
 * @requires jQuery
 * @requires ./subscriptionEnum - for event definitions
 * @requires ./dispatchEvent - for dispatching step change events
 *
 * @exports {function} init - Initializes the stepper navigation module.
 */

import $ from 'jquery';
import { Events } from './subscriptionEnum';
import { dispatchNewStep, dispatchResetStep } from './dispatchEvent';

const buttonStepper = () => {
	/**
	 * @typedef {object} StepperElements
	 * @property {string} container - CSS selector for the stepper container element.
	 * @property {string} stepContainer - CSS selector for individual step elements.
	 * @property {string} button - CSS selector for navigation buttons (all share this base).
	 * @property {string} headItem - CSS selector for step headers.
	 */
	/**
	 * Configuration options for the stepper navigation.
	 * @type {StepperElements}
	 */
	const el = {
		container: '.mi-offerte-stepper',
		stepContainer: '.mi-offerte-stepper__step',
		stepContainerChild: '.mi-offerte-stepper__step-child',
		button: '.mi-offerte-stepper__button',
		headItem: '.mi-offerte-stepper__stepHead--item',
		formOfferte: '#offerte-form',
		headerCheckbox: '.mi-offerte-stepper__checkboxVisual',
		// panelCount: '.js-panel-quantity-input',
		panelCount: '#inputRange_quantity',
		pricePanelTotal: '.js-price-panel-total',
	};

	let currentStep = -1;

	const init = () => {
		if ( $( el.container ).length ) exec();
	};

	/**
	 * Executes the core logic of the stepper navigation.
	 * This function finds buttons, attaches event listeners for step changes and form interaction,
	 * and handles click events for "next" and "prev" buttons.
	 */
	const exec = () => {
		const $button = $( el.container ).find( `${ el.button }` );

		$( document ).on( `${ Events.STEP_INCREMENT }`, function ( event ) {
			const currentStep = event.detail.currSteps;

			if (
				$( el.headItem ).eq( currentStep ).attr( 'data-required' ) === 'true' &&
				$( el.headerCheckbox ).prop( 'checked' ) === false
			) {
				$button.filter( '.next' ).prop( 'disabled', true );
			} else if ( $( el.headItem ).eq( currentStep ).attr( 'data-required' ) === 'false' ) {
				$button.filter( '.next' ).prop( 'disabled', false );
			}
		} );

		$( document ).on( Events.FORM_IS_INTERACTED, function ( event ) {
			currentStep = event.detail.currSteps;
			$button.each( function () {
				const $this = $( this );

				if ( $this.is( '.next' ) ) {
					// $this.prop( 'disabled', false );

					const $headCurrStep = $( el.headItem ).eq( currentStep - 1 );

					const $activeStepContainer = $( `${ el.stepContainer }${ currentStep }` );
					const $checkedInput = $activeStepContainer.find(
						'.mi-offerte-stepper__btnWrapper input:checked'
					);
					const activeTypeDakId = $checkedInput.val();

					const isRequired = Boolean( $headCurrStep.attr( 'data-required' ) );
					const isCheckboxChecked = $( el.headerCheckbox ).prop( 'checked' );
					const $uncheckedRadios = $( `#${ activeTypeDakId }` )
						.find( '.inputRadio__wrapper' )
						.filter( function () {
							return ! $( this ).find( 'input' ).prop( 'checked' );
						} );

					if ( isRequired && ! isCheckboxChecked && $uncheckedRadios.length > 0 ) {
						setTimeout( () => {
							$this.prop( 'disabled', true );
						}, 1 );
					} else {
						$this.prop( 'disabled', false );
					}
				}

				if ( ( $this.is( '.prev' ) || $this.is( '.cancel' ) ) && currentStep !== 0 ) {
					$this.show();
					$this.removeAttr( 'disabled' );
				}
			} );
		} );

		$button.filter( '.next' ).on( 'click', function ( event ) {
			event.preventDefault();

			var panelcount = parseFloat( $( el.panelCount ).val() );

			if ( ! isNaN( panelcount ) && panelcount > 100 ) {
				$( 'html, body' ).animate(
					{
						scrollTop: $( el.formOfferte ).offset().top,
					},
					500
				);
			} else {
				dispatchNewStep( true, $( this ) );
				scrollToTopContainer();
			}
		} );

		$button.filter( '.prev' ).on( 'click', function ( event ) {
			event.preventDefault();

			dispatchNewStep( false, $( this ) );

			scrollToTopContainer();
		} );

		$button.filter( '.cancel' ).on( 'click', function ( event ) {
			event.preventDefault();

			const $headerItem = $( el.container ).find( el.headItem );
			$headerItem.removeClass( 'active' ).removeClass( 'done' );
			$headerItem.eq( 0 ).addClass( 'active' );

			const $stepContainerChild = $( el.container ).find( el.stepContainerChild );
			$stepContainerChild.removeClass( 'hide' ).addClass( 'hide' );
			$stepContainerChild.eq( 0 ).removeClass( 'hide' );

			const $pricePanelTotal = $( el.pricePanelTotal );
			$pricePanelTotal.html(
				'<span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">€</span>&nbsp;0,00</bdi></span>'
			);

			$( el.container )
				.find( "input[type='radio']" )
				.each( function () {
					$( this ).prop( 'checked', $( this ).attr( 'checked' ) );
				} );
			$( el.container ).find( "input[type='checkbox']" ).prop( 'checked', false );
			$( el.container )
				.find( 'input[type=number]' )
				.each( function () {
					const $this = $( this );
					$this.val( $this.attr( 'value' ) ?? 0 );
				} );
			$( el.container )
				.find( 'input[type=range]' )
				.each( function () {
					const $this = $( this );
					$this.val( $this.attr( 'value' ) ?? 0 );
					$this.trigger( 'input' );
				} );

			dispatchResetStep( event.currentTarget );

			scrollToTopContainer();
		} );

		const scrollToTopContainer = () => {
			window.scrollTo( {
				top:
					document.querySelector( el.container ).getBoundingClientRect().top +
					window.scrollY -
					32,
				behavior: 'smooth',
			} );
		};
	};

	init();
};

try {
	buttonStepper();
} catch ( error ) {
	throw Error( error );
}
