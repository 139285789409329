/**
 * Enum for common Steps & Events.
 * @readonly
 * @enum { value }
 */

const Steps = Object.freeze( {
	STEP_0: 'STEP_0',
	STEP_1: 'STEP_1',
	STEP_2: 'STEP_2',
	STEP_3: 'STEP_3',
} );

const Events = Object.freeze( {
	FORM_IS_INTERACTED: 'form-is-interacted',
	STEP_INCREMENT: 'form-step-increment',
	STEP_DECREMENT: 'form-step-decrement',
	ADD_TO_CART: 'form-add-to-cart',
} );

export { Steps, Events };
