import $ from 'jquery';
import { Events } from './subscriptionEnum';

const dispatchNewStep = ( isIncrement, btnEl ) => {
	const event = isIncrement ? Events.STEP_INCREMENT : Events.STEP_DECREMENT;
	const currentStep = parseInt( $( btnEl ).attr( 'data-step' ) ) + ( isIncrement ? -1 : 1 );

	const newStepEvent = new CustomEvent( event, {
		detail: {
			currSteps: currentStep,
			prevSteps: currentStep - 1,
			nextSteps: currentStep + 1,
		},
	} ); // Dispatch the custom event

	document.dispatchEvent( newStepEvent );
};

const dispatchResetStep = ( btnEl ) => {
	$( btnEl )
		.parent()
		.find( '.mi-offerte-stepper__button' )
		.each( function () {
			const $this = $( this );
			if ( $this.is( '.next' ) ) {
				$( btnEl ).attr( 'data-step', 1 );
			}
			if ( $this.is( '.prev' ) ) {
				$( btnEl ).attr( 'data-step', -1 );
			}
		} );
	const currentStep = 1;

	const newStepEvent = new CustomEvent( Events.STEP_DECREMENT, {
		detail: {
			currSteps: currentStep,
			prevSteps: currentStep - 1,
			nextSteps: currentStep + 1,
			reset: true,
		},
	} ); // Dispatch the custom event

	document.dispatchEvent( newStepEvent );
};

const dispatchInteractedEvent = ( currentStep, value ) => {
	const interactedEvent = new CustomEvent( Events.FORM_IS_INTERACTED, {
		detail: {
			currSteps: currentStep,
			value: value,
		},
	} ); // Dispatch the custom event

	document.dispatchEvent( interactedEvent );
};

const dispatchAddToCart = ( currentStep ) => {
	const lastStepEvent = new CustomEvent( Events.ADD_TO_CART, {
		detail: {
			currSteps: currentStep,
		},
	} ); // Dispatch the custom event

	document.dispatchEvent( lastStepEvent );
};

export { dispatchNewStep, dispatchInteractedEvent, dispatchResetStep, dispatchAddToCart };
