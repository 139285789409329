import $ from 'jquery';
import { debounce } from '../../utils/utils';

/**
 * @module inputToggle
 * @description Handles toggle input interactions to enable/disable associated
 * field groups.
 */

const inputToggle = () => {
	const el = {
		container: '.mi-offerte-stepper',
		stepContainer: '.mi-offerte-stepper__step-child',
		toggle: 'input[type="checkbox"].toggle',
		fieldGroup: '.mi-offerte-stepper__fieldGroup',
	};

	let $container = $();

	/**
	 * Defines a variable to hold the cached jQuery object for the container element.
	 */
	let defineVar = () => {
		$container = $( el.container );
	};

	/**
	 * Initializes the module.
	 */
	const init = () => {
		$( function () {
			defineVar();
			if ( $container.length ) exec();
		} );
	};

	/**
	 * Attaches event listeners to toggle inputs and handles field group enabling
	 * /disabling.
	 */
	const exec = () => {
		$container.find( el.toggle ).on(
			'input',
			debounce( function () {
				/**
				 * Finds the field group checkboxes within the same step container.
				 */
				const $fieldGroupCheckbox = $( this )
					.closest( el.stepContainer )
					.find( el.fieldGroup + ' input[type=checkbox]' );

				/**
				 * Disables field group checkboxes if the toggle is unchecked.
				 */
				if ( $( this ).is( ':not(:checked)' ) ) {
					$fieldGroupCheckbox.prop( 'checked', false );
					$fieldGroupCheckbox.prop( 'disabled', true );
				} else {
					/**
					 * Enables field group checkboxes if the toggle is checked.
					 */
					$fieldGroupCheckbox.prop( 'disabled', false );
				}
			}, 150 )
		);
	};

	init();
};

try {
	inputToggle();
} catch ( error ) {
	throw Error( error );
}
