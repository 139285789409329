import $ from 'jquery';
import { dispatchInteractedEvent } from './dispatchEvent';
import { Events } from './subscriptionEnum';
import { debounce } from '../../utils/utils';

const typeDakSelection = () => {
	const el = {
		container: '.mi-offerte-stepper',
		wrapper: '.mi-offerte-stepper__step-child',
		bodyWrapper: '.mi-offerte-stepper__step-child .body',
		inputBtn: '.mi-offerte-stepper__inputBtn',
		fieldGroup: '.mi-offerte-stepper__fieldGroup-col2',
		inputRadio__wrapper: '.inputRadio__wrapper',
	};

	let $inputBtn = $();
	let $inputRadio__wrapper = $();
	let isInteracted = false;
	let currentStep = -1;

	/**
	 * Defines and caches jQuery objects for frequently used elements.
	 */
	const defineVar = () => {
		$inputBtn = $( `${ el.container } ${ el.bodyWrapper }` ).find( el.inputBtn );
		$inputBtn.first().prop( 'checked', true );
		$inputRadio__wrapper = $( `${ el.container } ${ el.bodyWrapper }` ).find(
			el.inputRadio__wrapper
		);
	};

	const init = () => {
		$( function () {
			defineVar();
			if ( $inputBtn.length ) {
				exec();
			}
		} );
	};

	/**
	 * Handles step change events and input interactions.
	 */
	const exec = () => {
		// Handle step changed event
		$( document ).on( Events.STEP_INCREMENT, function ( event ) {
			currentStep = event.detail.nextSteps;
			handleHeadingCheckbox();
		} );

		$( document ).on( Events.STEP_DECREMENT, function ( event ) {
			currentStep = event.detail.prevSteps;
			handleHeadingCheckbox();
		} );

		// Handle input button & radio button
		$inputBtn.on(
			'input change',
			debounce( function ( event ) {
				handleBtnFieldGroup( event );

				if ( currentStep === 1 ) {
					dispatchInteractedEvent( currentStep, event.currentTarget.value );
				}
			}, 150 )
		);

		$inputRadio__wrapper.on( 'input', function ( event ) {
			handleInputRadio( event );

			if ( currentStep === 1 ) {
				dispatchInteractedEvent( currentStep, event.target );
			}
		} );
	};

	const handleHeadingCheckbox = () => {
		const $wrapperInputRadio = $( el.fieldGroup ).eq( 0 ).find( el.inputRadio__wrapper );
		if (
			$wrapperInputRadio.is( ':hidden' ) &&
			$wrapperInputRadio.length === 1 &&
			currentStep === 1
		) {
			$( el.container + '__step1' )
				.find( '.head input[type=checkbox]' )
				.prop( 'checked', true );
		}
	};

	/**
	 * Handles input button interactions, showing/hiding field groups based on button value.
	 *
	 * @param {Event} event - The input event object.
	 */
	const handleBtnFieldGroup = ( event ) => {
		const $currEl = $( event.currentTarget );
		const value = $currEl.val();

		const $wrapper = $currEl.closest( el.bodyWrapper );
		const $stepValue = $wrapper.find( el.fieldGroup + '#' + value );

		// Uncheck all radio buttons in the field group
		$wrapper.find( el.fieldGroup ).find( 'input[type=radio]' ).prop( 'checked', false );

		// Get the number of radio buttons in the current step
		const radioCount = $stepValue.find( 'input[type=radio]' ).length;

		// Get the checkbox in the header of the current wrapper
		const $headerCheckbox = $currEl.closest( el.wrapper ).find( '.head input[type=checkbox]' );

		if ( radioCount < 2 ) {
			$headerCheckbox.prop( 'checked', true );

			// Click the label next to the first radio button in the specified field group
			$wrapper
				.find( `${ el.fieldGroup }#${ value } input[type=radio]:first + label` )
				.trigger( 'click' );
		} else {
			$headerCheckbox.prop( 'checked', false );
		}

		isInteracted = false;

		$wrapper
			.find( el.fieldGroup )
			.filter( ':visible' )
			.fadeOut( 240, function () {
				$wrapper.find( `#${ value }` ).fadeIn( 240 );
			} );
	};

	/**
	 * Handles input radio button interactions, checking the corresponding checkbox.
	 *
	 * @param {Event} event - The input event object.
	 */
	const handleInputRadio = ( event ) => {
		const $currTarget = $( event.currentTarget );

		if ( ! isInteracted ) {
			$currTarget
				.closest( el.wrapper )
				.find( '.head input[type=checkbox]' )
				.prop( 'checked', true );

			isInteracted = true;
		}
	};

	init();
};

try {
	typeDakSelection();
} catch ( error ) {
	throw Error( error );
}
